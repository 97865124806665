<template>
  <div class="pt-4 pb-0">
    <v-card-text class="px-1 pb-2">
      <v-row class="mb-4 px-2">
        <v-col v-if="actadescripcion.length > 0" cols="12" sm="12" md="12" class="py-0">
          <h1>{{ actadescripcion }}</h1>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="py-0">
          <v-text-field
            dense
            label="Título"
            v-model="titulo"
            required
            :rules="[required]"
            @blur="titulo = titulo.toUpperCase()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" class="py-0">
          <div>
            <v-data-table
              dense
              :headers="cParticipantes"
              :items="participantes"
              :items-per-page="-1"
              sort-by="calories"
              class="border"
              hide-default-footer
            >
              <template v-slot:top>
                <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                  <div class="d-flex align-center py-1">
                    <u>I</u>NVITADOS/PARTICIPANTES
                    <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    {{ participacion }} %
                    <v-spacer></v-spacer>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn color="white" fab icon small @click="addRows" v-shortkey="['ctrl', 'i']" @shortkey="addRows">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.dni`]="{ item }">
                <v-edit-dialog :return-value.sync="item.dni">
                  {{ item.dni }}
                  <template v-slot:input>
                    <v-text-field v-model="item.dni" label="Dni" single-line type="number"></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-edit-dialog :return-value.sync="item.object" @save="saveName(item.id, item.object, item.new)">
                  {{ item.name }}
                  <template v-slot:input>
                    <div v-if="!item.new" class="d-flex align-baseline">
                      <v-autocomplete
                        v-model="item.object"
                        :search-input.sync="search"
                        :items="itemsParticipantes"
                        item-text="name"
                        item-value="code"
                        label="Nombres"
                        placeholder="Nombres"
                        prepend-icon="mdi-database-search"
                        hide-no-data
                        hide-selected
                        return-object
                      >
                        <template v-slot:selection="data">
                          {{ data.item.name }}
                        </template>
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              <v-list-item-subtitle>
                                {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                      <v-btn icon color="primary" @click="changeNewEditText(item.id)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <v-text-field
                      v-else
                      v-model="item.name"
                      @blur="item.name = item.name.toUpperCase()"
                      label="Nombres"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <v-edit-dialog @save="saveEmail(item.id, item.email)">
                  {{ item.email }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.email"
                      label="Email"
                      :suffix="item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com'"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItemParticipantes(item.id)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <span>No se encontró información</span>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
      <v-row class="my-4 px-2">
        <v-col cols="12" sm="12" class="py-0">
          <div>
            <v-data-table
              dense
              :headers="cAcuerdos"
              :items="acuerdos"
              :items-per-page="-1"
              sort-by="calories"
              class="border"
              hide-default-footer
            >
              <template v-slot:top>
                <div class="primary w-100 d-flex justify-space-between px-2 white--text body-1" style="height: 40px;">
                  <div class="d-flex align-center py-1">
                    <u>A</u>CUERDOS
                    <v-divider class="mx-4" inset vertical style="background:#fff"></v-divider>
                    <v-spacer></v-spacer>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn
                      color="white"
                      fab
                      icon
                      small
                      class="mb-2"
                      @click="addRowsAgreement"
                      v-shortkey="['ctrl', 'a']"
                      @shortkey="addRowsAgreement"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.engagement`]="{ item }">
                <v-edit-dialog :return-value.sync="item.engagement">
                  {{ item.engagement }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="item.engagement"
                      @blur="item.engagement = item.engagement.toUpperCase()"
                      label="Compromiso"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.responsible`]="{ item }">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                  <div>
                    <ul class="common-list my-2">
                      <li :key="i" v-for="(e, i) in item.responsible">
                        {{ e.name }}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <v-btn icon color="primary" @click="openDialogResponsibles(item.id)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.area`]="{ item }">
                <div style="display: flex;align-items: center;">
                  <div>
                    <ul class="common-list my-2">
                      <li :key="i" v-for="(e, i) in item.responsible">
                        {{ e.subarea ? e.subarea : 'OTROS' }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.tipo`]="{ item }">
                <v-edit-dialog :return-value.sync="item.tipo">
                  {{ item.tipo == 'A' ? 'ACUERDO' : 'COMPROMISO' }}
                  <template v-slot:input>
                    <v-select
                      v-model="item.tipo"
                      :items="itemsTipoAC"
                      no-data-text="No se encontró información"
                      item-text="text"
                      item-value="value"
                      label="Estado"
                      single-line
                    >
                    </v-select>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <v-edit-dialog :return-value.sync="item.date">
                  {{
                    item.date
                      .split('-')
                      .reverse()
                      .join('-')
                  }}
                  <template v-slot:input>
                    <v-date-picker
                      ref="picker"
                      v-model="item.date"
                      locale="es-es"
                      :first-day-of-week="1"
                      landscape
                    ></v-date-picker>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.status`]>
                <v-chip color="blue" dark> Por Vencer</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteItemAcuerdos(item.id)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <span>No se encontró información</span>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pt-3">
      <v-spacer></v-spacer>
      <v-btn color="error" @click="cancelar()">Cerrar</v-btn>
      <v-btn v-auth-acl="'gh-act-act_seg-insert'" color="primary" submit @click="guardar()">Guardar</v-btn>
    </v-card-actions>

    <v-dialog v-model="dialog" scrollable max-width="300px">
      <v-card>
        <v-card-title>Seleccione Responsable</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <div>
            <v-checkbox
              v-model="selectedResponsibleAll"
              label="TODOS"
              @change="changeSelectedResponsibleAll"
            ></v-checkbox>
            <v-checkbox
              v-model="selectedResponsible"
              :label="e.name"
              :value="e"
              :key="i"
              v-for="(e, i) in itemsResponsible"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="closeDialog">Cerrar</v-btn>
          <v-btn color="primary" text @click="confirmDialog">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ActaService from '../services/ActaService';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { decryptAES } from '@/components4x/utils/utils4x';

export default {
  name: 'MiniActasReunionFormulario',
  components: {},
  props: {
    idreferencia: {
      type: String,
      default: ''
    },
    referencia: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showLoading: false,
    actaService: null,
    dialog: false,
    selectedResponsibleAll: false,
    actadescripcion: '',
    tiporeferencia: '',
    titulo: '',
    required: (v) => v.length !== 0 || 'El campo no debe estar vacío',
    cParticipantes: [
      {
        text: 'Nombres',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Dni',
        align: 'start',
        sortable: false,
        value: 'dni'
      },
      { text: 'Correo', value: 'email' },
      /*    { text: 'Lider', value: 'leader' },
      { text: 'Estado', value: 'status' }, */
      { text: 'Acciones', value: 'actions' }
    ],
    participantes: [],
    cAcuerdos: [
      { text: 'N°', align: 'start', value: 'index', width: '100' },
      { text: 'Compromisos', value: 'engagement' },
      { text: 'Responsable', value: 'responsible' },
      { text: 'Área', value: 'area' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Fecha', value: 'date' },
      { text: 'Estado', value: 'status' },
      { text: 'Acciones', value: 'actions' }
    ],
    acuerdos: [],
    selectStatus: { value: false, text: 'NO' },
    itemsStatus: [
      { value: 'S', text: 'Si' },
      { value: 'I', text: 'Invitado' },
      { value: 'N', text: 'No' }
    ],
    itemsTipoAC: [
      { value: 'A', text: 'ACUERDO' },
      { value: 'C', text: 'COMPROMISO' }
    ],
    selectStatusResponsible: { value: 'PV', color: 'blue', text: 'Por Vencer' },
    itemsStatusResponsible: [
      { value: 'RE', color: 'green', text: 'Realizado' },
      { value: 'PV', color: 'blue', text: 'Por Vencer' },
      { value: 'NR', color: 'red', text: 'No Realizado' },
      { value: 'CA', color: 'back', text: 'Cancelado' }
    ],
    itemsResponsible: [],
    itemsParticipantes: [],
    selectedResponsible: [],
    idResponsible: '',
    participacion: 0,
    search: ''
  }),
  computed: {},
  methods: {
    async initialize() {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('responsables', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });

      if (res.length > 0) {
        res.forEach((el) => {
          this.itemsParticipantes.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            name: el.nombresall,
            email: el.email,
            idsubarea: el.idsubarea,
            subarea: el.subarea,
            area: el.area, // En realidad es la subarea
            leader: false,
            new: false,
            object: {}
          });
        });
      } else {
        this.itemsParticipantes = [];
      }

      this.showLoading = false;
    },
    async guardar() {
      if (this.titulo.length <= 0) {
        await this.alertDialog('warning', 'Ingrese un título');
        return;
      }

      if (this.participantes.length <= 0) {
        await this.alertDialog('warning', 'No hay invitados/participantes');
        return;
      }
      let auxDni = false;
      this.participantes.forEach((el) => {
        if (el.dni == '' || el.nombre == '') {
          auxDni = true;
        }
      });
      if (auxDni) {
        this.alertDialog('warning', 'Falta dni/nombres de algunos participantes');
        return;
      }
      if (this.acuerdos.length <= 0) {
        await this.alertDialog('warning', 'Los acuerdos están vacíos');
        return;
      }
      let auxResponsible = false;
      let auxEngagement = false;
      this.acuerdos.forEach((el) => {
        if (el.responsible.length <= 0) {
          auxResponsible = true;
        }
        if (el.engagement == '') {
          auxEngagement = true;
        }
      });

      if (auxResponsible) {
        this.alertDialog('warning', 'Faltan responsables en algunos compromisos/acuerdos');
        return;
      }
      if (auxEngagement) {
        this.alertDialog('warning', 'Faltan nombres en algunos compromisos/acuerdos');
        return;
      }

      const participants = [];

      this.participantes.forEach((el) => {
        if (el.name != '') {
          participants.push({
            idresponsable: el.code,
            idtrabajador: el.dni,
            nombres: el.name.toUpperCase(),
            email: el.email,
            idarea: el.idsubarea,
            area: el.area
          });
        }
      });

      const agreements = [];

      this.acuerdos.forEach((el) => {
        const responsibles = [];
        el.responsible.forEach((elem) => {
          responsibles.push({
            idtrabajador: elem.dni,
            responsable: elem.name,
            area: elem.idsubarea,
            email: elem.email
          });
        });
        agreements.push({
          item: el.index,
          compromiso: el.engagement,
          tipo: el.tipo,
          responsables: responsibles,
          fechafin: el.date,
          observaciones: ''
        });
      });

      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de guardar los cambios?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const res = await this.actaService.post().execResource('actas', {
          idempresa: decryptAES(localStorage.getItem('emp')),
          descripcion: '',
          idreferencia: '',
          tiporeferencia: 'S',
          titulo: this.titulo,
          idlider: '',
          porparticipantes: '',
          dagendas: '',
          dparticipantes: participants,
          dacuerdos: agreements,
          obs: ''
        });

        this.showLoading = false;

        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.$router.push({ path: '/actas/matriz-aac' });
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async cancelar() {
      await this.$router.push({ path: '/actas/matriz-aac' });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    changeSelectedResponsibleAll() {
      this.selectedResponsible = [];
      if (this.selectedResponsibleAll) {
        this.itemsResponsible.forEach((el) => {
          this.selectedResponsible.push({ ...el });
        });
      }
    },
    changeLeader(id, codigo) {
      this.participantes.map((el) => (el.leader = el.id === id && el.codigo === codigo));
    },
    addRows() {
      this.participantes.push({
        id: uuidv4(),
        dni: '',
        code: '',
        name: '',
        email: '',
        area: '',
        idsubarea: '',
        subarea: '',
        new: false,
        object: {}
      });
    },
    addRowsAgreement() {
      const index = this.acuerdos.length <= 0 ? 1 : this.acuerdos.length + 1;
      this.acuerdos.push({
        id: uuidv4(),
        index: index.toString().padStart(3, '0'),
        engagement: '',
        responsible: [],
        tipo: 'A',
        date: new Date().toISOString().substr(0, 10),
        status: { value: 'PV', color: 'blue', text: 'POR VENCER' }
      });
    },
    // CELL EDIT
    loadItemsResponsible() {
      this.itemsResponsible = [];
      this.participantes.forEach((el) => {
        if (el.dni !== '')
          if (el.idsubarea != '')
            this.itemsResponsible.push({
              id: el.id,
              code: el.code,
              dni: el.dni,
              name: el.name,
              email: el.email,
              area: el.area,
              idsubarea: el.idsubarea,
              subarea: el.subarea
            });
      });
    },
    clearRowInvitados(id) {
      const i = this.participantes.findIndex((el) => el.id === id);
      this.participantes.splice(i, 1);
    },
    saveName(id, object, inew) {
      if (inew) return;

      if (object === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const dni = object.dni;

      if (dni === '' || dni === undefined) {
        this.clearRowInvitados(id);
        return;
      }

      const res = this.itemsParticipantes.filter((el) => el.dni === dni);
      this.participantes.map((el) => {
        if (el.id === id) {
          el.dni = res[0].dni;
          el.code = res[0].code;
          el.name = res[0].name;
          el.email = res[0].email;
          el.idsubarea = res[0].idsubarea;
          el.subarea = res[0].subarea;
          el.area = res[0].area;
        }
        return el;
      });
    },
    openDialogResponsibles(id) {
      this.loadItemsResponsible();

      this.selectedResponsible = [];
      this.idResponsible = id;
      this.dialog = true;
      const res = this.acuerdos.filter((el) => el.id === id);

      if (res[0].responsible.length <= 0) return;

      for (const arr of res[0].responsible) {
        this.selectedResponsible.push(Object.assign({}, arr));
      }
    },
    closeDialog() {
      this.selectedResponsible = [];
      this.idResponsible = '';
      this.selectedResponsibleAll = false;
      this.dialog = false;
    },
    confirmDialog() {
      let res = [];
      for (const responsible of this.selectedResponsible) {
        res.push(Object.assign({}, responsible));
      }

      this.acuerdos.map((el) => (el.responsible = el.id === this.idResponsible ? res : el.responsible));

      this.selectedResponsible = [];
      this.selectedResponsibleAll = false;
      this.dialog = false;
    },
    deleteItemParticipantes(id) {
      const i = this.participantes.findIndex((el) => el.id === id);
      this.participantes.splice(i, 1);
    },
    deleteItemAcuerdos(id) {
      const i = this.acuerdos.findIndex((el) => el.id === id);
      this.acuerdos.splice(i, 1);
      this.acuerdos.map((el, index) => (el.index = (index + 1).toString().padStart(3, '0')));
    },
    changeNewEditText(id) {
      this.participantes.map((el) => (el.new = el.id === id ? true : el.new));
    },
    saveEmail(id) {
      this.participantes.map(
        (el) =>
          (el.email =
            el.id === id ? (el.email.indexOf('@') !== -1 ? el.email : `${el.email}@agrovisioncorp.com`) : el.email)
      );
    },
    async loadEvent(id) {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('eventos', {
        idevento: id
      });
      this.showLoading = false;

      if (!Array.isArray(res)) {
        this.actadescripcion = `Evento: "${res.asunto.toUpperCase()}" ( ${res.start.split('.')[0]} ~ ${
          res.end.split('.')[0]
        } )`;

        this.tiporeferencia = res.tipo;

        res.invitados.forEach((e) => {
          const res = this.itemsParticipantes.filter((el) => el.dni === e.idtrabajador);
          this.participantes.push({
            id: uuidv4(),
            dni: res[0].dni,
            code: res[0].code,
            name: res[0].name,
            email: res[0].email,
            area: res[0].area,
            idsubarea: res[0].idsubarea,
            subarea: res[0].subarea,
            new: false,
            object: {}
          });
        });
      } else {
        this.actadescripcion = '';
        this.participantes = [];
        await this.alertDialog('error', 'Evento no existe');
        await this.$router.push({ path: '/calendario-comites/evento' });
      }
    },
    async loadComite(id) {
      this.showLoading = true;
      const res = await this.actaService.get().execResource('comites', {
        idcomite: id
      });
      this.showLoading = false;
      if (!Array.isArray(res)) {
        this.actadescripcion = `Comité: "${res.titulo.toUpperCase()}" ( ${res.start.split('.')[0]} ~ ${
          res.end.split('.')[0]
        } )`;

        this.tiporeferencia = res.tipo;

        if (res.invitados.length > 0) {
          res.invitados.forEach((e) => {
            const res = this.itemsParticipantes.filter((el) => el.dni === e.idtrabajador);
            this.participantes.push({
              id: uuidv4(),
              dni: res[0].dni,
              code: res[0].code,
              name: res[0].name,
              email: res[0].email,
              area: res[0].area,
              idsubarea: res[0].idsubarea,
              subarea: res[0].subarea,
              new: false,
              object: {}
            });
          });
        }

        if (res.temasatratar.length > 0) {
          res.temasatratar.forEach((e) => {
            this.agenda.push({ id: uuidv4(), index: e.index, description: e.tema });
          });
        }
      } else {
        this.actadescripcion = '';
        this.participantes = [];
        await this.alertDialog('error', 'Evento no existe');
        await this.$router.push({ path: '/calendario-comites/evento' });
      }
    }
  },
  async mounted() {},
  async created() {
    this.actaService = this.$httpService(new ActaService(), this);
    await this.initialize();

    if (this.idreferencia === '' || this.referencia === '') {
      return;
    }

    switch (this.referencia) {
      case 'E':
        await this.loadEvent(this.idreferencia);
        break;
      case 'C':
        await this.loadComite(this.idreferencia);
        break;
    }
  }
};
</script>
