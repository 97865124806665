var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4 pb-0"},[_c('v-card-text',{staticClass:"px-1 pb-2"},[_c('v-row',{staticClass:"mb-4 px-2"},[(_vm.actadescripcion.length > 0)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h1',[_vm._v(_vm._s(_vm.actadescripcion))])]):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Título","required":"","rules":[_vm.required]},on:{"blur":function($event){_vm.titulo = _vm.titulo.toUpperCase()}},model:{value:(_vm.titulo),callback:function ($$v) {_vm.titulo=$$v},expression:"titulo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('div',[_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":_vm.cParticipantes,"items":_vm.participantes,"items-per-page":-1,"sort-by":"calories","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary w-100 d-flex justify-space-between px-2 white--text body-1",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"d-flex align-center py-1"},[_c('u',[_vm._v("I")]),_vm._v("NVITADOS/PARTICIPANTES "),_c('v-divider',{staticClass:"mx-4",staticStyle:{"background":"#fff"},attrs:{"inset":"","vertical":""}}),_vm._v(" "+_vm._s(_vm.participacion)+" % "),_c('v-spacer')],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'i']),expression:"['ctrl', 'i']"}],attrs:{"color":"white","fab":"","icon":"","small":""},on:{"click":_vm.addRows,"shortkey":_vm.addRows}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)])]},proxy:true},{key:"item.dni",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.dni},on:{"update:returnValue":function($event){return _vm.$set(item, "dni", $event)},"update:return-value":function($event){return _vm.$set(item, "dni", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Dni","single-line":"","type":"number"},model:{value:(item.dni),callback:function ($$v) {_vm.$set(item, "dni", $$v)},expression:"item.dni"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.dni)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.object},on:{"update:returnValue":function($event){return _vm.$set(item, "object", $event)},"update:return-value":function($event){return _vm.$set(item, "object", $event)},"save":function($event){return _vm.saveName(item.id, item.object, item.new)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(!item.new)?_c('div',{staticClass:"d-flex align-baseline"},[_c('v-autocomplete',{attrs:{"search-input":_vm.search,"items":_vm.itemsParticipantes,"item-text":"name","item-value":"code","label":"Nombres","placeholder":"Nombres","prepend-icon":"mdi-database-search","hide-no-data":"","hide-selected":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((data.item.dni) + " - " + (data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS')))+" ")])],1)]]}}],null,true),model:{value:(item.object),callback:function ($$v) {_vm.$set(item, "object", $$v)},expression:"item.object"}}),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.changeNewEditText(item.id)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_c('v-text-field',{attrs:{"label":"Nombres","single-line":""},on:{"blur":function($event){item.name = item.name.toUpperCase()}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.saveEmail(item.id, item.email)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Email","suffix":item.email.indexOf('@') !== -1 ? '' : '@agrovisioncorp.com',"single-line":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemParticipantes(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No se encontró información")])]},proxy:true}],null,true)})],1)])],1),_c('v-row',{staticClass:"my-4 px-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12"}},[_c('div',[_c('v-data-table',{staticClass:"border",attrs:{"dense":"","headers":_vm.cAcuerdos,"items":_vm.acuerdos,"items-per-page":-1,"sort-by":"calories","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"primary w-100 d-flex justify-space-between px-2 white--text body-1",staticStyle:{"height":"40px"}},[_c('div',{staticClass:"d-flex align-center py-1"},[_c('u',[_vm._v("A")]),_vm._v("CUERDOS "),_c('v-divider',{staticClass:"mx-4",staticStyle:{"background":"#fff"},attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'a']),expression:"['ctrl', 'a']"}],staticClass:"mb-2",attrs:{"color":"white","fab":"","icon":"","small":""},on:{"click":_vm.addRowsAgreement,"shortkey":_vm.addRowsAgreement}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)])]},proxy:true},{key:"item.engagement",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.engagement},on:{"update:returnValue":function($event){return _vm.$set(item, "engagement", $event)},"update:return-value":function($event){return _vm.$set(item, "engagement", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Compromiso","single-line":""},on:{"blur":function($event){item.engagement = item.engagement.toUpperCase()}},model:{value:(item.engagement),callback:function ($$v) {_vm.$set(item, "engagement", $$v)},expression:"item.engagement"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.engagement)+" ")])]}},{key:"item.responsible",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',[_c('ul',{staticClass:"common-list my-2"},_vm._l((item.responsible),function(e,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(e.name)+" ")])}),0)]),_c('div',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openDialogResponsibles(item.id)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)])]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('ul',{staticClass:"common-list my-2"},_vm._l((item.responsible),function(e,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(e.subarea ? e.subarea : 'OTROS')+" ")])}),0)])])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.tipo},on:{"update:returnValue":function($event){return _vm.$set(item, "tipo", $event)},"update:return-value":function($event){return _vm.$set(item, "tipo", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.itemsTipoAC,"no-data-text":"No se encontró información","item-text":"text","item-value":"value","label":"Estado","single-line":""},model:{value:(item.tipo),callback:function ($$v) {_vm.$set(item, "tipo", $$v)},expression:"item.tipo"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.tipo == 'A' ? 'ACUERDO' : 'COMPROMISO')+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.date},on:{"update:returnValue":function($event){return _vm.$set(item, "date", $event)},"update:return-value":function($event){return _vm.$set(item, "date", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{ref:"picker",attrs:{"locale":"es-es","first-day-of-week":1,"landscape":""},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.date .split('-') .reverse() .join('-'))+" ")])]}},{key:"item.status",fn:function(){return [_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(" Por Vencer")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemAcuerdos(item.id)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No se encontró información")])]},proxy:true}],null,true)})],1)])],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cerrar")]),_c('v-btn',{directives:[{name:"auth-acl",rawName:"v-auth-acl",value:('gh-act-act_seg-insert'),expression:"'gh-act-act_seg-insert'"}],attrs:{"color":"primary","submit":""},on:{"click":function($event){return _vm.guardar()}}},[_vm._v("Guardar")])],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"300px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Seleccione Responsable")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"400px"}},[_c('div',[_c('v-checkbox',{attrs:{"label":"TODOS"},on:{"change":_vm.changeSelectedResponsibleAll},model:{value:(_vm.selectedResponsibleAll),callback:function ($$v) {_vm.selectedResponsibleAll=$$v},expression:"selectedResponsibleAll"}}),_vm._l((_vm.itemsResponsible),function(e,i){return _c('v-checkbox',{key:i,attrs:{"label":e.name,"value":e},model:{value:(_vm.selectedResponsible),callback:function ($$v) {_vm.selectedResponsible=$$v},expression:"selectedResponsible"}})})],2)]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.confirmDialog}},[_vm._v("Guardar")])],1)],1)],1),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }