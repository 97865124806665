class ActaService {
  constructor() {}
  resources = () => ({
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    actas: {
      uri: `/v1/actas/guardar`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    eventos: {
      uri: `/v1/calendario/evento`,
      method: ['get']
    },
    comites: {
      uri: `/v1/calendario/comite`,
      method: ['get']
    },
    tareas: {
      uri: `/v1/proyectosone`,
      method: ['get']
    }
  });
}

export default ActaService;
